import barba from '@barba/core';
import { gsap } from "gsap";
import LocomotiveScroll from 'locomotive-scroll';
import './css/locomotive-scroll.css';
import mute from '../src/images/SVG/mute.svg'
import sound_on from '../src/images/SVG/sound_on.svg'
import emailjs from '@emailjs/browser';
import tutu_char1 from './images/tutu/Abena.webp'
import tutu_char2 from './images/tutu/Anokye.webp'
import tutu_char3 from './images/tutu/tutu_char3.webp'
import tutu_char4 from './images/tutu/tutu_char4.webp'
import tutu_char5 from './images/tutu/tutu_char5.webp'
import tutu_char6 from './images/tutu/tutu_char6.webp'
import tutu_char7 from './images/tutu/tutu_char7.webp'
import tutu_char8 from './images/tutu/tutu_char8.webp'

import poster1 from './images/tutu/posters/poster1.webp'
import poster2 from './images/tutu/posters/poster2.webp'
import poster3 from './images/tutu/posters/poster3.webp'
import poster4 from './images/tutu/posters/poster4.webp'
import poster5 from './images/tutu/posters/poster5.webp'
import poster6 from './images/tutu/posters/poster6.webp'
import poster7 from './images/tutu/posters/poster7.webp'
import poster8 from './images/tutu/posters/poster8.webp'
import poster9 from './images/tutu/posters/poster9.webp'

//Jabari Images
import jabari1 from "./images/Jabari_f2/image1.webp"
import jabari2 from "./images/Jabari_f2/image2.webp"
import jabari3 from "./images/Jabari_f2/image3.webp"
import jabari4 from "./images/Jabari_f2/image4.webp"
import jabari5 from "./images/Jabari_f2/image5.webp"
import jabari6 from "./images/Jabari_f2/image6.webp"
import jabari7 from "./images/Jabari_f2/image7.webp"
import jabari8 from "./images/Jabari_f2/image8.webp"
import jabari9 from "./images/Jabari_f2/image9.webp"
import jabari10 from "./images/Jabari_f2/image10.webp"
import jabari11 from "./images/Jabari_f2/image11.webp"
import jabari12 from "./images/Jabari_f2/image12.webp"

import jabari_video from "./images/Jabari_f2/jabari.mp4"

//Mmofra Images
import mmofra1 from "./images/mmofra_f2/promo_1.webp"
import mmofra2 from "./images/mmofra_f2/promo_2.webp"
import mmofra3 from "./images/mmofra_f2/promo_3.webp"
import mmofra4 from "./images/mmofra_f2/promo_4.webp"
import mmofra5 from "./images/mmofra_f2/promo_5.webp"
import mmofra6 from "./images/mmofra_f2/promo_6.webp"
import mmofra7 from "./images/mmofra_f2/promo_7.webp"
import mmofra8 from "./images/mmofra_f2/promo_8.webp"
import mmofra9 from "./images/mmofra_f2/promo_9.webp"
import mmofra10 from "./images/mmofra_f2/promo_10.webp"
import mmofra11 from "./images/mmofra_f2/promo_11.webp"
import mmofra12 from "./images/mmofra_f2/promo_12.webp"
import mmofra13 from "./images/mmofra_f2/promo_13.webp"
import mmofra14 from "./images/mmofra_f2/promo_14.webp"
import mmofra_cover from "./images/mmofra_cover.png"

//Play button
import play_butt from "./images/play_button.png"

//Moongirls Images
import moon1 from "./images/moongirl_f2/moon1.webp"
import moon2 from "./images/moongirl_f2/moon2.jpg"
import moon3 from "./images/moongirl_f2/moon3.webp"
import moon4 from "./images/moongirl_f2/moon4.webp"
import moon5 from "./images/moongirl_f2/moon5.webp"

//Bakari in Safari
import bakari from "./video/bakari.mp4"
import bakari_img from "./images/BAKERY_ON_SAFARI_cover.webp"

//Nike Mimi x Plange
const nike_video_actual = 'https://www.dropbox.com/scl/fi/usmcviz59lsivdb3k1itk/The-Sentinels_Final.mp4?rlkey=fpoge0v3oy0a5jx8q8e121gbp&st=66vk8ft0&raw=1'
const sentinel_mobile = 'https://www.dropbox.com/scl/fi/wu9joh7jywpn653umvjux/The-Sentinels_mobile.mp4?rlkey=6nqflxn6owpenjdygipehri46&st=73c0xi5g&raw=1'
import nike_poster from "./images/sentinels_poster.webp"
import nike_poster2 from './images/nike_poster.webp'
import nike_vid from './video/nike.mp4'

//Animax Show Reel
var video_reel = "https://www.dropbox.com/scl/fi/a6iw0pvyectd0mile1g9u/Animaxfyb_2023_Showreel.mp4?rlkey=n89ip4d09at8rthn43tev19yc&st=pk0d42u3&raw=1"
import reel_poster from "./images/reel_poster.jpeg"

//TV/Movies Videos
import room5 from "./video/Room_5.mp4"
import kilo_kwataa from "./video/kilo_kwataa.mp4"
import xena from "./video/Xena.mp4"
import way2go from "./video/Way2go.mp4"
import joe_boy from ".//video/joe_boy.mp4"
import argokoli from "./video/Agorkoli.mp4"

//TV/Movies Posters
import room5_poster from "./images/tv_movies/room5_cover.webp"
import kilo_kwataa_poster from "./images/tv_movies/Kilo_Kwaata_Poster.webp"
import xena_poster from "./images/tv_movies/Zena.webp"
import way2go_poster from "./images/tv_movies/Zena.webp"
import joe_boy_poster from "./images/tv_movies/joe_boy_2.webp"
import argokoli_poster from "./images/agorkoli.jpg"

//Highlights Videos
import uniliver from "./video/highlights/uniliver.mp4"
import gates from "./video/highlights/gates.mp4"
import ident from "./video/nike.mp4"
import french_embassy from "./video/highlights/french_embassy.mp4"
import goal_setters from "./video/highlights/goal_setters.mp4"
import unicef from "./video/highlights/unicef.mp4"

//Highlights Posters
import uniliver_poster from "./images/highlights/unilever.webp"
import gates_poster from "./images/highlights/the_gates.webp"
import ident_poster from "./images/highlights/nike_mimi.webp"
import french_embassy_poster from "./images/highlights/french_embassy.webp"
import goal_setters_poster from "./images/goal_setters.jpg"
import unicef_poster from "./images/highlights/unicef.webp"

//mute/unmute button imgs
import mute_button_img from "./images/mute-button.png"
import unmute_button from "./images/unmute.png"

//Tutu video
var tutu_video = "https://www.dropbox.com/scl/fi/sikxabz4tmd2ly69nispp/Tutu-The-Untold-Story-of-a-Kingdom-Proof-of-Concept-Animation.mp4?rlkey=0v1wo8bfvd43v58ifayxppyas&raw=1" 
var behind_the_scenes = "https://www.dropbox.com/scl/fi/csoxp6uq5fehp56kvycnj/behind_the_scenes.mp4?rlkey=fa7v5prlyhnn63dgb27ha5t2i&raw=1"
var tutu_animatics_video = "https://www.dropbox.com/scl/fi/uvvqgfqyzvx5ymc5c98bc/animatics.mp4?rlkey=53g36xzby58z5ur3ryytj26vj&raw=1"
import script_to_screen from "./images/Tutu_f2/Script To Screen.mkv"
import title_reveal_video from "./images/Tutu_f2/Title Reveal.mkv"

import tutu_video_poster from "./images/Tutu_f2/tutu_cover.jpg"
import behind_the_scenes_poster from "./images/Tutu_f2/tutu-behind-the-scene.webp"
import tutu_animatics_video_poster from "./images/Tutu_f2/TUTU - animatics copy.webp"
import script_to_screen_poster from "./images/Tutu_f2/script_to_screen.webp"
import title_reveal_video_poster from "./images/Tutu_f2/title_reveal.webp"

//Mmofra Promo Videos
import promo1 from "./video/mmofra_videos_downsized/promo1.mp4"
import promo2 from "./video/mmofra_videos_downsized/promo2.mkv"
import promo3 from "./video/mmofra_videos_downsized/promo3.mkv"
import promo4 from "./video/mmofra_videos_downsized/promo4.mkv"
import promo5 from "./video/mmofra_videos_downsized/promo5.mp4"
import promo6 from "./video/mmofra_videos_downsized/promo6.mkv"
import promo7 from "./video/mmofra_videos_downsized/promo7.mkv"
import promo8 from "./video/mmofra_videos_downsized/promo8.mkv"
import promo9 from "./video/mmofra_videos_downsized/promo9.mkv"
import promo10 from "./video/mmofra_videos_downsized/promo10.mkv"
import promo11 from "./video/mmofra_videos_downsized/promo11.mkv"
import promo12 from "./video/mmofra_videos_downsized/promo12.mkv"
import promo13 from "./video/mmofra_videos_downsized/promo13.mkv"
import promo14 from "./video/mmofra_videos_downsized/promo14.mkv"

import promo_poster1 from "./images/mmofra_f2/promo_1.webp"
import promo_poster2 from "./images/mmofra_f2/promo_2.webp"
import promo_poster3 from "./images/mmofra_f2/promo_3.webp"
import promo_poster4 from "./images/mmofra_f2/promo_4.webp"
import promo_poster5 from "./images/mmofra_f2/promo_5.webp"
import promo_poster6 from "./images/mmofra_f2/promo_6.webp"
import promo_poster7 from "./images/mmofra_f2/promo_7.webp"
import promo_poster8 from "./images/mmofra_f2/promo_8.webp"
import promo_poster9 from "./images/mmofra_f2/promo_9.webp"
import promo_poster10 from "./images/mmofra_f2/promo_10.webp"
import promo_poster11 from "./images/mmofra_f2/promo_11.webp"
import promo_poster12 from "./images/mmofra_f2/promo_12.webp"
import promo_poster13 from "./images/mmofra_f2/promo_13.webp"
import promo_poster14 from "./images/mmofra_f2/promo_14.webp"






const scroll = new LocomotiveScroll({
  el: document.querySelector('[data-scroll-container]'),
  smooth: true,
  mobile: {
    breakpoint:0,
    smooth: false,
    multiplier: 15,
  },
  tablet: {
    breakpoint:0,
    smooth: false,
    multiplier: 1,
  }
});

setTimeout(() => {
  scroll.update();
}, 5000);


barba.hooks.beforeLeave((data) => {
  scroll.destroy();
  
});

barba.hooks.after((data) => {
 scroll.init();
 
});



const animationEnter = (container)=>{
  return gsap.from(container,{
    x:-800,
    duration:1,
    delay:0.4,
    ease:"power4.inOut"
  })
}

const animationLeave = (container)=>{
  return gsap.to(container,{
    x:100,
    duration:1,
    onComplete:function(){
      done()
    }
  })
}

barba.init({
    transitions:[
      {
        name: 'opacity-transition',
        once({next}){
          animationEnter(next.container)
        },
        leave({current}){
          console.log('leave')

          var transition1 = $('.transition1');
          var transition2 = $('.transition2');
          var transition_video = $('.transition_video');
          var transition_image = $('.transition1 img')
          transition_video.hide()
          transition_image.show()
          const transition_holder = $('.transition_holder')          
          gsap.set(transition1,
              {x:'-100%',
              alpha:1,
              
          })
          gsap.to(transition1,{
              x:0,
              duration: 1, 
              ease: "power4.inOut",
              delay:.2
              
          })
          gsap.to(transition1,{
              x:"100%",
              delay:1.4,
              duration: 1, 
              ease: "power4.inOut"
          })

          //transition2

          gsap.set(transition2,
              {x:'-100%',
              alpha:1
          })
          gsap.to(transition2,{
              x:0,
              duration: 1, 
              ease: "power4.inOut"
              
          })
          gsap.to(transition2,{
              x:"100%",
              delay:1.5,
              duration: 1, 
              ease: "power4.inOut"
          })

          transition_video[0].currentTime = 0;

          const done = this.async();

          return gsap.to(current.container,{
            x:800,
            duration:1,
            ease: "power4.inOut",
            onComplete:function(){
              done() 

            }
          })
          
        },
        enter({next}){
          animationEnter(next.container)
          console.log('enter')
        }
      
     
  }],

    views: [{
        namespace: 'home',
        beforeEnter() {
          const footer_text = $('footer .container .flexbox .flex:nth-child(1) p')
          const slice_text = footer_text.text()
          const small_screens = window.matchMedia('(max-width:380px)');

          if(small_screens.matches){
            slice_text_template =`
            ${slice_text.slice(0,150)}... <a href="about.html" style="color:white;">Read More</a>
            `
            footer_text.html(slice_text_template)
    
              console.log(slice_text.slice(0,150))
          }
          
        },
        afterEnter() {
          
            //variables
            var video_container = $('.video_container')
            var play_button = $('.play_button')
            var video = $('.video_container .video video')
            var nike_vid = $('.nike_vid')
            var high_play_button_1 = $('.high_play_button_1')
            var high_play_button_2 = $('.high_play_button_2')
            var high_play_button_3 = $('.high_play_button_3')
            var high_play_button_4 = $('.high_play_button_4')
            var high_play_button_5 = $('.high_play_button_5')
            var nike_lebron_play_button = $('.nike_lebron_play_button')

            var tv_movies_play_button1 = $('.tv_movies_play_button_1')
            var tv_movies_play_button2 = $('.tv_movies_play_button_2')
            var tv_movies_play_button3 = $('.tv_movies_play_button_3')
            var tv_movies_play_button4 = $('.tv_movies_play_button_4')
            var tv_movies_play_button5 = $('.tv_movies_play_button_5')
            var tv_movies_play_button6 = $('.tv_movies_play_button_6')
            var jabari_trailer_button = $('.jabari_trailer_button')
            var watch_reel = $('.watch_reel')
            var watch_reel_button = $(".watch_reel_button")
            var nike_mute_button = $('.nike_mute_button')
            var tv_movies_mute_button =$(".tv_movies_mute_button")
            var activation_mute_button = $(".activation_mute_button")
            var nike_playbutton = $(".nike_playbutton")

            var mobile_room5_play_button = $(".mobile_room5_play_button")
            var mobile_kilo_play_button = $(".mobile_kilo_play_button")
            var mobile_xena_play_button = $(".mobile_xena_play_button")
            var mobile_way2go_play_button = $(".mobile_way2go_play_button")
            var mobile_joe_boy_play_button = $(".mobile_joe_boy_play_button")
            var mobile_argokoli_play_button = $(".mobile_argokoli_play_button")
            
            nike_mute_button.click(soundOn)
            tv_movies_mute_button.click(tv_movies_soundOn)
            activation_mute_button.click(activation_soundOn)
            
            
            
            

            //Mobile View Functions
            const mobile_view = window.matchMedia('(max-width:900px)')
            

            var wip_video = $('.wip_video')
            var activation_video = $('.activation_video')

            wip_video[0].volume = 0
            activation_video[0].volume = 0
            
            //Create a new Vimeo.Player object
            var vimeo_video = $('#vimeo_player')
            var player = new Vimeo.Player(vimeo_video);
        
            //When the player is ready, set the volume to 0
            player.ready().then(function() {
                player.setVolume(0);
            });
            

            //click functions
            high_play_button_1.click(hightlightVideo1)
            high_play_button_2.click(hightlightVideo2)
            high_play_button_3.click(hightlightVideo3)
            high_play_button_4.click(hightlightVideo4)
            high_play_button_5.click(hightlightVideo5)

            tv_movies_play_button1.add(mobile_room5_play_button).click(tvMoviesVideo1)
            tv_movies_play_button2.add(mobile_kilo_play_button).click(tvMoviesVideo2)
            tv_movies_play_button3.add(mobile_xena_play_button).click(tvMoviesVideo3)
            tv_movies_play_button4.add(mobile_way2go_play_button).click(tvMoviesVideo4)
            tv_movies_play_button5.add(mobile_joe_boy_play_button).click(tvMoviesVideo5)
            tv_movies_play_button6.add(mobile_argokoli_play_button).click(tvMoviesVideo6)


            jabari_trailer_button.click(featureVideo)
            nike_lebron_play_button.add(nike_playbutton).click(lebronPromo)
            watch_reel.add(watch_reel_button).click(showReel)

            function showReel(){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:'block'
            })
              video.attr('src',video_reel)
             video.attr('poster', reel_poster)
              video[0].play()

              if(mobile_view.matches){
                video[0].requestFullscreen()
              }
            }

            function lebronPromo(){

              
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:'block'
            })


              video.attr('src',nike_video_actual)
              video.attr('poster',nike_poster)
              video[0].play()

              if(mobile_view.matches){
                video[0].requestFullscreen()
                video.attr('src',sentinel_mobile)
              }
              
            }

            function featureVideo(){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:'block'
            })
              video.attr('src','https://drive.google.com/uc?export=view&id=12WwOizWWUQDZ7lW0_izKl9_vp5zjVjgr')
              video.attr('poster','https://drive.google.com/uc?export=view&id=1FiWdfWzSHBA7BMsKa4aeVHRJ-HV0-r7d')
              video[0].play()

              
              
            }

            function hightlightVideo1(){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:'block'
            })
              video.attr('src',uniliver)
              video.attr('poster',uniliver_poster)
              video[0].play()
              
            }
           
            function hightlightVideo2(){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:'block'
            })
              video.attr('src',gates)
              video.attr('poster',gates_poster)
              video[0].play()
              
            }
 
            function hightlightVideo3(){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:'block'
            })
              video.attr('src',ident)
              video.attr('poster',ident_poster)
              video[0].play()
            }
 
            function hightlightVideo4(){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:'block'
            })
              video.attr('src',french_embassy)
              video.attr('poster',french_embassy_poster)
              video[0].play()
            }
 
            function hightlightVideo5(){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:'block'
            })
            
              video.attr('src',goal_setters)
              video.attr('poster',goal_setters_poster)
              video[0].play()
            }
 
          //   function hightlightVideo6(){
          //    video_container.css({
          //      opacity:1,
          //      visibility:'visible',
          //      display:'block'
          //  })
          
          //    video.attr('src',unicef)
          //    video.attr('poster',unicef_poster)
          //    video[0].play()
          //  }
            function tvMoviesVideo1(){
              video_container.css({
                opacity:1,
                visibility:'visible'
            })
              video.attr('src',room5)
              video.attr('poster', room5_poster)
              video[0].play()

             
             
            }

            function tvMoviesVideo2(){
              video_container.css({
                opacity:1,
                visibility:'visible'
            })
              video.attr('src',kilo_kwataa)
              video.attr('poster',kilo_kwataa_poster)
              video[0].play()
              
            }

            function tvMoviesVideo3(){
              video_container.css({
                opacity:1,
                visibility:'visible'
            })
              video.attr('src',xena)
              video.attr('poster', xena_poster)
              video[0].play()
              
            }

            function tvMoviesVideo4(){
              video_container.css({
                opacity:1,
                visibility:'visible'
            })
              video.attr('src',way2go)
              video.attr('poster',way2go_poster)
              video[0].play()
             
            }

            function tvMoviesVideo5(){
              video_container.css({
                opacity:1,
                visibility:'visible'
            })
              video.attr('src',joe_boy)
              video.attr('poster',joe_boy_poster)
              video[0].play()
              
            }

            function tvMoviesVideo6(){
              video_container.css({
                opacity:1,
                visibility:'visible'
            })
              video.attr('src',argokoli)
              video.attr('poster',argokoli_poster)
             
              video[0].play()
              
            }

            //SoundOn function
            var unmute = false
            var tv_movie_mute = false
            var activation_mute = false
            var nike_mute_img = $(".nike_mute_button img")
            var tv_movies_mute_img = $(".tv_movies_mute_button img")
            var activation_mute_button_img = $('.activation_mute_button img')
            var nike_video = $("#nike_video")
            var activation_vid = $('.activation_vid')
            
            
            nike_video[0].volume = 0;
            activation_vid[0].volume = 0
            function soundOn(){
              if(unmute = !unmute){
                nike_mute_img.attr("src", mute_button_img)
                nike_video[0].volume = 0;
                
              }else{
                nike_mute_img.attr("src", unmute_button)
               
                nike_video[0].volume = 1;
                
              }
            }
            unmute=!unmute

            function tv_movies_soundOn(){
              if(tv_movie_mute =!tv_movie_mute){
                player.ready().then(function() {
                  player.setVolume(0);
              });
              tv_movies_mute_img.attr("src",mute_button_img)
              }else{
                player.ready().then(function() {
                  player.setVolume(1);
              });
              tv_movies_mute_img.attr("src",unmute_button)
              }
            }

            tv_movie_mute =!tv_movie_mute

            function activation_soundOn(){
                if(activation_mute=!activation_mute){
                  activation_vid[0].volume = 0
                  activation_mute_button_img.attr("src", mute_button_img)
                }else{
                  activation_vid[0].volume = 1
                  activation_mute_button_img.attr("src", unmute_button)
                }
            }

            activation_mute=!activation_mute

            $(document).ready(main)

            $(window).on('load',function(){
              //$('main,nav').css('opacity',1);
              const latest_project = $('.latest_project')

              function preloader(url,callback){
                var img = new Image();
                img.src = url;
                img.onload = callback;
                
                //cover this with a gif loader

                // latest_project.css({
                //   'background-image':`url(${url})`
                // })

              }

              preloader(jabari_poster,function(){
                // console.log('images loaded')
              })


              //Mobile View Functions
              const mobile_view = window.matchMedia('(max-width:900px)')

              if(mobile_view.matches){
                preloader(jabari_poster_mobile,function(){
                  
                  console.log('images loaded')
                })
                
                latest_project.css({
                  'background-position':"center",
                  
                })

                $(window).scroll(function(){
                  var scrollTop = $(window).scrollTop();
                  const main_sec_studio = $('.main_sec_studio');
                  const main_sec_two = $('.main_sec_two')
                  const main_sec_three = $('.main_sec_three')
                  const studio_label = $('.studio_label')
                  const sec_studio_overlay = $('.sec_studio .overlay')
                  const sec_two_overlay = $('.main_sec_two .overlay')
                  const sec_three_overlay = $('.main_sec_three .overlay')
                  const label = $('.label')
                  const label_activation = $('.label_activation')

                  studio_label.css("opacity","0")

                  if(scrollTop > main_sec_studio.offset().top - 200){
                    studio_label.css({
                      opacity:1,
                      'margin-top':'0px'
                    })

                    sec_studio_overlay.css({
                      opacity:1
                    })
                  }else{
                    studio_label.css({
                      opacity:0,
                      'margin-top':'100px'
                    })
                  }

                  if(scrollTop> main_sec_two.offset().top - 200){
                    label.css({
                      opacity:1,
                      'margin-top':"0px"
                    })

                    sec_two_overlay.css({
                      opacity:1
                    })
                  }else{
                    label.css({
                      opacity:0,
                      'margin-top':"100px"
                    })

                  }

                  if(scrollTop> main_sec_three.offset().top - 200){
                    label_activation.css({
                      opacity:1,
                      'margin-top':"0px"
                    })

                    sec_three_overlay.css({
                      opacity:1
                    })
                  }else{
                    label_activation.css({
                      opacity:0,
                      'margin-top':"100px"
                    })

                  }
                })
              }

              //Mobile View Functions Ends Here 
              
            
                // End of Window OnLoad
            })

            function main(){

               
              var toggle_bool = false;

              //Mobile navigation
              const menu = $('.menu');
              const mobile_nav_menu = $('.mobile_nav_menu')
              const mobile_nav_menu_container = $('.mobile_nav_menu .container')
              const mobile_menu_li = $('.mobile_menu ul li')
              menu.click(ShowMobileNav)

              function ShowMobileNav(){
                mobile_nav_menu.show()
                
                
              if(!toggle_bool){
                gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
                  alpha:1,
                  x:0,
                  delay:.1,
                  ease:'power4.out',
                 
                })
              }else{
                gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
                  x:'100px',
                  alpha:0,
                  ease:'power4.out'
                 
                })

             
                  setTimeout(function(){
                    mobile_nav_menu.hide()
                  },200)
                 
                
               
              }
              toggle_bool =!toggle_bool;

                
              }

              mobile_menu_li.click(function(){
                toggle_bool = false;
                gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
                  delay:.5,
                  x:'100px',
                  alpha:0,
                  ease:'power4.out'
                 
                })

                setTimeout(function(){
                  mobile_nav_menu.hide()
                },600)
              })

              

              


                $(window).scroll(scrollFunction)
            
                function scrollFunction(){
                  var scrollTop = $(window).scrollTop();
                  var main_sec_one = $('.main_sec_one');
                  var main_sec_two = $('.main_sec_two');
                  var main_sec_three = $('.main_sec_three');
                  var main_sec_studio = $('.main_sec_studio');
                  var latest_project = $('.latest_project');
                
          
                  var windowHeight = $(window).height();
                  var sec_one = $('.sec_one');
                  var sec_two = $('.sec_two');
                  var sec_three = $('.sec_three');
                  var sec_studio = $('.sec_studio');
                  var nav = $('.navigation');
                  var label = $('.label')
                  var label_h1 = $('.label h1')
                  var studio_label = $('.studio_label')
                  var studio_label_h1 = $('.studio_label h1')
                  var label_activation = $('.label_activation')
                  var label_activation_h1 = $('.label_activation h1')
                  var studio_button_trans1 = $('.studio_label .button_trans1')
                  var studio_button_trans2 = $('.studio_label .button_trans2')
                  var wip_button_trans1 = $('.label .button_trans1')
                  var wip_button_trans2 = $('.label .button_trans2')
                  var activation_button_trans1 = $('.label_activation .button_trans1')
                  var activation_button_trans2 = $('.label_activation .button_trans2')
                  
                  var label_button = $('.label button')
                  var studio_label_button = $('.studio_label button')
                  var label_activation_button = $('.label_activation button')
                 
                   
            
                    if(scrollTop > main_sec_one.offset().top){
                        var fillScreen = (107 - Math.max(0,Math.min(scrollTop/5,13)))
                       
                        var clamp =  Math.max(0,Math.min(scrollTop,60))
                       
                        sec_one.css({
                            //width:fillScreen + '%',
                           //height:fillScreen + 'vh',
                            //'border-radius':clamp + 'px',
                            transition:'.3s',
                            
                        })
            
                        
                    }
            
                    
                    
            
                   
                }
            
                //Banner Carousel
                var carousel_index = 2;
                var move_value = 50;
                var value = 0;
                var index = 1;
                var carousel_time = 10000;
                var banner_holder = $('.banner_holder');
                var banner_caption= $('.banner_caption')
                var circle1 = $('.circle1')
                var circle2 = $('.circle2')
                var bannerlabel_h1 = $('.banner_label h1')
                var arrow_left = $('.arrow_left')
                var arrow_right = $('.arrow_right')
                var latest_project = $('.latest_project')
                var tv_movies_audio_control = $('.tv_movies_audio_control')
                var wip_audio_control = $('.wip_audio_control')
                var activations_audio_control = $('.activations_audio_control')
                var watchReel_button = $('.button');
                
                var video_container = $('.video_container')
                var video_reel = $('.video_container .video video')
                var close_button = $('.close_button');
                var wip_video = $('.wip_video')
                var activation_video = $('.activation_video')
                var time_elapse = 4000;
                var label = $('.label')
                var studio_label = $('.studio_label')
                var label_activation = $('.label_activation')
                var studio_overlay = $('.sec_studio .overlay')
                var wip_overlay = $('.sec_two .overlay')
                var activations_overlay = $('.sec_three .overlay')
                //Booleans
                var bool1 = false;
                var bool2 = false;
                var bool3 = false;

               
            
                wip_video[0].volume = 0
                activation_video[0].volume = 0
                //Create a new Vimeo.Player object
                var video = $('#vimeo_player')
                var player = new Vimeo.Player(video);
            
                //When the player is ready, set the volume to 0
                player.ready().then(function() {
                    player.setVolume(0);
                });
            
                //click functions
                tv_movies_audio_control.click(tvMoviesAudioControl)
                wip_audio_control.click(wipAudioControl)
                activations_audio_control.click(activationAudioControl)
                //watchReel_button.click(showVideo)
                close_button.click(closeVideo)
            
                //EventListeners
                video_container.on('mousemove',showCloseButton)
            
                watchReel_button.click(function(){
                  window.location.href = "https://forms.gle/QQ8K5JtN2y9Qr6Tk7";
                })
            
                function tvMoviesAudioControl(){
                    
                   if(!bool1){
                       $(this).attr('src',`${sound_on}'`)
            
                       player.ready().then(function() {
                        player.setVolume(1);
                        studio_label.hide()
                        studio_overlay.css({
                            'background':'rgba(0,0,0,0)'
                        })
                        
                    });
                   }else{
                       $(this).attr('src',`${mute}`)
                       player.ready().then(function() {
                        player.setVolume(0);
                        studio_label.show()
                        studio_overlay.css({
                            'background':'rgba(0,0,0,0.2)'
                        })
                    });
                   }
            
                   bool1 =!bool1
                }
            
                function wipAudioControl(){
                    
                    if(!bool2){
                        $(this).attr('src',`${sound_on}'`)
                        wip_video[0].volume = 1
                        label.hide()
                        wip_overlay.css({
                            'background':'rgba(0,0,0,0)'
                        })
                    }else{
                        $(this).attr('src',`${mute}`)
                        wip_video[0].volume = 0
                        label.show()
                        wip_overlay.css({
                            'background':'rgba(0,0,0,0.2)'
                        })
                    }
             
                    bool2 =!bool2
                 }
                
                 function activationAudioControl(){
                    
                    if(!bool3){
                        $(this).attr('src',`${sound_on}'`)
                        activation_video[0].volume = 1
                        label_activation.hide()
                        activations_overlay.css({
                            'background':'rgba(0,0,0,0)'
                        })
                    }else{
                        $(this).attr('src',`${mute}`)
                        activation_video[0].volume = 0
                        label_activation.show()
                        activations_overlay.css({
                            'background':'rgba(0,0,0,0.2)'
                        })
                    }
             
                    bool3 =!bool3
                 }
            
                function showVideo(){
                    video_container.css({
                        opacity:1,
                        visibility:'visible',
                        display:'block'
                    })
            
                    video_reel[0].play()
                }
            
                function closeVideo(){
                    video_container.css({
                        opacity:0,
                        visibility:'hidden',
                        display:"none"
                    })
            
                    video_reel[0].pause()
                   
                }
            
                function closeButtonAction(){
                    close_button.css({
                        opacity:1,
                        top:'5%'
                    })
            
                }
            
                function showCloseButton (){
                   closeButtonAction();
                }
                
                 
                
            
                function carousel(){
                    if(index < carousel_index){
                        index++;
                        value += move_value
            
                        function randomIntFromInterval(min, max) { // min and max included 
                            return Math.floor(Math.random() * (max - min + 1) + min)
                          }
                          
                          const rndInt = randomIntFromInterval(1, 2)
                        
            
                          /*latest_project.css({
                            'background-image':`url(https://drive.google.com/uc?export=view&id=1pWXO6CSWsdLk9tFs_3pxwhxNAYoZZPqJ)`
                           
                            })*/
                               
            
                               
                          
            
                        banner_holder.css({
                            'transform':`translate(-${value}%)`
                        })
            
                        
            
                        circle2.css({
                            'transform':'scale(1.4)'
                        })
            
                        circle1.css({
                            'transform':'scale(1)'
                        })
                        $('.watchReel_button').text('Watch Reel')
            
                       /* bannerlabel_h1.css({
                            opacity:0
                        })*/
                        watchReel_button.click(function(){
                        
                          video_container.css({
                              opacity:1,
                              visibility:'visible',
                              display:'block'
                          })
                  
                          video_reel[0].play()
                      
                  
                      })

                        // watchReel_button.click(function(){
                        //   window.location.href = "/jabari.html";
                        // })

                        watchReel_button.show()

                        bannerlabel_h1.show()
                    }else{

                      watchReel_button.click(function(){
                        video_container.css({
                          opacity:1,
                          visibility:'visible',
                          display:'none'
                      })
                        window.location.href = "https://forms.gle/tpAY25yWxkyaMKYf6";
                      })

                      //$('.carousel_buttons .flexbox').hide()
                  
                    
                    //   watchReel_button.click(function(){
                        
                    //     video_container.css({
                    //         opacity:1,
                    //         visibility:'visible',
                    //         display:'block'
                    //     })
                
                    //     video_reel[0].play()
                    
                
                    // })
                        

                        index=1;
                        value=0;
                        banner_holder.css({
                            'transform':`translate(${value}%)`
                        })
                        console.log(value)
            
                        circle1.css({
                            'transform':'scale(1.4)'
                        })
            
                        circle2.css({
                            'transform':'scale(1)'
                        })
            
                        bannerlabel_h1.css({
                            opacity:1
                        })
            
                        bannerlabel_h1.hide()
                        $('.banner_label .button').hide()

                        $('.watchReel_button') .text('Register')

                        
                       
                       
                    }
                }
            
                //setInterval(carousel,carousel_time)
            
                arrow_right.click(function(){
                    if(index === 1){
                        value += move_value
                        banner_holder.css({
                            'transform':`translate(-${value}%)`
                        })
                        bannerlabel_h1.css({
                            opacity:1
                        })
                        $('.watchReel_button').text('Explore Jabari')
                        index++  
                    }
                })
            
                arrow_left.click(function(){
                    if(index > 1){
                       
                        index=1;
                        value=0;
                        banner_holder.css({
                            'transform':`translate(${value}%)`
                        })
                        bannerlabel_h1.css({
                            opacity:1
                        })
                        $('.watchReel_button') .text('Watch Our Reel')
            
                        clearInterval(carousel)
                    }
                })

                
            
            
            
                //Gsap Animations
                var tl = gsap.timeline();
                var transition1 = $('.transition1');
                var transition2 = $('.transition2');
                //var navigations = $('a')
                gsap.set(transition1,
                    {x:'-100%',
                    alpha:1
                })
                gsap.to(transition1,{
                    x:0,
                    duration: 1, 
                    ease: "power4.inOut",
                    delay:.2
                    
                })
                gsap.to(transition1,{
                    x:"100%",
                    delay:5,
                    duration: 1, 
                    ease: "power4.inOut"
                })
            
                //transition2
            
                gsap.set(transition2,
                    {x:'-100%',
                    alpha:1
                })
                gsap.to(transition2,{
                    x:0,
                    duration: 1, 
                    ease: "power4.inOut"
                    
                })
                gsap.to(transition2,{
                    x:"100%",
                    delay:5.1,
                    duration: 1, 
                    ease: "power4.inOut"
                })
            
                //End of Gsap Animation
               
            
            }

            //Premiere Gallery
            const premierArray = [
              'https://dl.dropboxusercontent.com/scl/fi/67wlge608q3rzlg73kq1c/premiere_1.webp?rlkey=ba6k062hpgv0uyct1761usy1j&dl=0',
              'https://dl.dropboxusercontent.com/scl/fi/vjd7cyirlyju4nd0qw6yp/premiere_2.webp?rlkey=uttkqzfnxqh3u9sbwzfi3yfu0&dl=0',
              'https://dl.dropboxusercontent.com/scl/fi/l8a5m56o83pibqbkcy5et/premiere_3.webp?rlkey=ahmeqetjcrwt5mgb91mfr29ge&dl=0',
              'https://dl.dropboxusercontent.com/scl/fi/lzneaze7wlqfnlr4cnc98/premiere_4.webp?rlkey=vkw4z5ks1lz5wv8dsmbd7xgs4&dl=0',
            
              'https://dl.dropboxusercontent.com/scl/fi/6o7oneppro84a0et6on5m/premiere_6.webp?rlkey=saoar0wmaxyocp1ftcaudxhr3&dl=0',
              'https://dl.dropboxusercontent.com/scl/fi/7m7dqsj2ibi2i8updz8de/premiere_7.webp?rlkey=amwrf7hlh9ykzpv2gkiilfukd&dl=0',
              'https://dl.dropboxusercontent.com/scl/fi/f744gvzuuwia6xoptgoxi/premiere_8.webp?rlkey=4738mb0pro7yn1vgdnngy54ms&dl=0',
              'https://dl.dropboxusercontent.com/scl/fi/5f0nbene3bxn3ps0f2p0z/premiere_9.webp?rlkey=4o2te12al15h3rpilogvcrx1w&dl=0',
              'https://dl.dropboxusercontent.com/scl/fi/no8let1ppzba4wpvqpslm/premiere_10.webp?rlkey=2jzb2a68xup55jtnvztdf91wk&dl=0',
              'https://dl.dropboxusercontent.com/scl/fi/nto5adynjbj7r214x31o9/premiere_11.webp?rlkey=1y95rwjtuj763qkcdhexesiqo&dl=0',
              'https://dl.dropboxusercontent.com/scl/fi/xgebeshagbraq63024waa/premiere_12.webp?rlkey=wzuzlacjfvj2nb9m2q2t179or&dl=0',
              'https://dl.dropboxusercontent.com/scl/fi/qkaokcqc359bi8cfll3lk/premiere_13.webp?rlkey=wxwgecyhd7wvuhs2orkjd6p69&dl=0',
            


            ]

             //Swipe to chnage pictures (Mobile touch gallery)

     

            const thumnail_flexbox = $('.thumbnail_flexbox')
            const arrow_right = $('.arrow_right')
            const arrow_left = $('.arrow_left')
            const thumbnail_arrow_left = $('.thumbnail_arrow_left')
            const thumbnail_arrow_right = $('.thumbnail_arrow_right')
            const main_premier_image = $('.main_premier_image')
            main_premier_image.attr('src', premierArray[0])
            const featured_banner = $('.featured_banner')
            const close_gallery = $('.close_gallery')
            const view_premier = $('.view_premier')
            var index = 0;
            const total_thumb_index = 1
            var thumb_index = 0
            var move = 0
            var value = 50

            view_premier.click(()=>{
              featured_banner.show()
             
            })

            thumbnail_arrow_right.click(()=>{
                if(thumb_index < total_thumb_index){
                  move+=value
                  thumnail_flexbox.css('transform',`translateX(-${move}%)`)
                  thumbnail_arrow_right.hide()
                  thumb_index++;
                }
            })

            thumbnail_arrow_left.click(()=>{
             
              if(thumb_index == total_thumb_index){
                thumb_index=0
                move=0
                thumnail_flexbox.css('transform',`translateX(-${move}%)`)
                thumbnail_arrow_right.show()
              }
            })

            arrow_right.click(()=>{
              if(index < premierArray.length){
                index++;
                main_premier_image.attr('src', premierArray[index])

                if(index == 11){
                 arrow_right.hide()
                }else{
                  arrow_right.show()
                }
              }
            })  

            arrow_left.click(()=>{
              if(index < premierArray.length && index > 0){
                index--;
                main_premier_image.attr('src', premierArray[index])
              }
            })
           

            for(premier of premierArray){
              const premier_thumbnail_template =`
              <div class="premier_thumbnails">
              <img class="premier_image" src=${premier} attr="animax premier photos">
              </div>
              `

              thumnail_flexbox.append(premier_thumbnail_template)

              
              
            }

            const premier_image = document.querySelectorAll('.premier_image')
            
            for(i of premier_image){
              function showImage(e){
                //alert(e.target.getAttribute('src'))
                const thisImage = e.target.getAttribute('src')
                main_premier_image.attr('src',thisImage)
              }

              i.addEventListener('click', showImage)
            }

            close_gallery.click(()=>{
              
              featured_banner.hide()
            })

            featured_banner.on('touchstart',TouchStart);
            featured_banner.on('touchmove',TouchMove); 
      
            var initialX = null;
            var initialY = null;
      
            function TouchStart(e) {
                initialX = e.touches[0].clientX;
                initialY = e.touches[0].clientY;
            };
      
            function TouchMove(e) {
                if (initialX === null) {
                  return;
                }
      
                if (initialY === null) {
                  return;
                }
      
                var currentX = e.touches[0].clientX;
                var currentY = e.touches[0].clientY;
      
                var diffX = initialX - currentX;
                var diffY = initialY - currentY;
      
                if (Math.abs(diffX) > Math.abs(diffY)) {
                  // sliding horizontally
                  if (diffX > 0) {
                    // swiped left
                    if(index < premierArray.length){
                      index++;
                      main_premier_image.attr('src', premierArray[index])
      
                      if(index == 11){
                       arrow_right.hide()
                      }else{
                        arrow_right.show()
                      }
                    }
      
                  } else {
                    // swiped right
                    if(index < premierArray.length && index > 0){
                      index--;
                      main_premier_image.attr('src', premierArray[index])
                    }
                  
                    
      
                  }  
                } 
      
                initialX = null;
                initialY = null;
      
                e.preventDefault();
              };

      
        
         //End of Home After Enter 
        }
        
      },
      {
        
      namespace: 'jabari',
      beforeEnter() {
     
        const footer_text = $('footer .container .flexbox .flex:nth-child(1) p')
        const slice_text = footer_text.text()
        const small_screens = window.matchMedia('(max-width:380px)');

        if(small_screens.matches){
          slice_text_template =`
          ${slice_text.slice(0,150)}... <a href="about.html" style="color:white;">Read More</a>
          `
          footer_text.html(slice_text_template)
  
            console.log(slice_text.slice(0,150))
        }
      },
      afterEnter(){
        var toggle_bool = false;

        //Mobile navigation
        const menu = $('.menu');
        const mobile_nav_menu = $('.mobile_nav_menu')
        const mobile_nav_menu_container = $('.mobile_nav_menu .container')
        const mobile_menu_li = $('.mobile_menu ul li')
        const mobile_view = window.matchMedia('(max-width:900px)')
        menu.click(ShowMobileNav)

        function ShowMobileNav(){
          mobile_nav_menu.show()

          
        if(!toggle_bool){
          gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
            alpha:1,
            x:0,
            delay:.1,
            ease:'power4.out',
           
          })
        }else{
          gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
            x:'100px',
            alpha:0,
            ease:'power4.out'
           
          })

       
            setTimeout(function(){
              mobile_nav_menu.hide()
            },200)
           
          
         
        }
        toggle_bool =!toggle_bool;

          
        }

        mobile_menu_li.click(function(){
          toggle_bool = false;
          gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
            delay:.5,
            x:'100px',
            alpha:0,
            ease:'power4.out'
           
          })

          setTimeout(function(){
            mobile_nav_menu.hide()
          },600)
        })

          //Jabari Gallery    

          var gallery_flexbox = $('.gallery_flexbox')
          var video_container = $('.video_container')
          var video = $('.video_container .video video')
          var jabari_video_container = $('.wip_video_container')
        
          var jabari_arrey = [
              jabari1,
              jabari2,
              jabari3,
              jabari4,
              jabari5,
              jabari6,
              jabari7,
              jabari8,
              jabari9,
              jabari10,
              jabari11,
              
          ]

          jabari_arrey.forEach((array_data,index)=>{
              const image_template =`
                  <div data-id='${index}' class='gallery_images' style="background:url(${array_data});background-size:cover; background-position:top;">
                    
                  </div>
              `
              
              gallery_flexbox.append(image_template);
          })

          //click Functions
          jabari_video_container.click(showJabariVideo)

          function showJabariVideo(){
            video_container.css({
              opacity:1,
              visibility:'visible',
              display:"block"
          })
          
            video.attr('src', jabari_video)
            video.attr('poster',jabari12)
           
            video[0].play()

            if(mobile_view.matches){
              video[0].requestFullscreen()
            }
            
          }

            //Jabari Explore Gallery
            const arrow_left = $('.arrow_left');
            const arrow_right = $('.arrow_right')
            const arrow_left_link = $('.arrow_left_link')
            const arrow_right_link = $('.arrow_right_link')
            const gallery_images = $('.gallery_images')
            const gallery_main_container = $('.jabari_gallery_main_container')
            const jabari_gallery_main_container = $('.jabari_gallery_main_container')
            const jabari_gallery = $('.jabari_gallery')
            const gallery= $('.gallery')
            var gallery_index = 0;
            var move=0
            var value = 7.5;

            gallery_images.click(showJabariImages)

            /*jabari_arrey.forEach((images,index)=>{
                const gallery_template = `
                <div id="jabari${index}">
                <img src="${images}" alt="animaxfyb gallery"> 
                </div>
                `
                jabari_gallery.append(gallery_template)
                
              
            })

           /* arrow_right.click(function(){

    
              if(gallery_index < jabari_arrey.length-1){
                  gallery_index++
                  console.log(gallery_index)
                  move+=value;
                  //arrow_right_link.attr('href',`#moon${gallery_index}`)
                  jabari_gallery.css({
                      "transform":`translateX(-${move}%)`,
                      
                  })
                 
                  
              }
          
            
             
          })
          
          arrow_left.click(function(){
          
              
              if(gallery_index < jabari_arrey.length && gallery_index > 0){
                  gallery_index--;
                  console.log(gallery_index)
                  move-=value;
                  //arrow_left_link.attr('href',`#moon${gallery_index}`)
                  jabari_gallery.css({
                      "transform":`translateX(-${move}%)`,
                      
                  })
                 
              }  
              
              
          })*/

          arrow_right.click(function(){
   

            if(gallery_index < jabari_arrey.length-1){
                gallery_index++
                jabari_gallery.css({
                    "background-image":`url(${jabari_arrey[gallery_index]})`
                }) 
                console.log(gallery_index)
                
            }
        
    })
    
    arrow_left.click(function(){
       
    
        if(gallery_index < jabari_arrey.length && gallery_index > 0){
            gallery_index--
            jabari_gallery.css({
                "background-image":`url(${jabari_arrey[gallery_index]})`
            }) 
            console.log(gallery_index)
            
        }
    
    })

    //Swipe to chnage pictures (Mobile touch gallery)

      jabari_gallery_main_container.on('touchstart',TouchStart);
      jabari_gallery_main_container.on('touchmove',TouchMove); 

      var initialX = null;
      var initialY = null;

      function TouchStart(e) {
          initialX = e.touches[0].clientX;
          initialY = e.touches[0].clientY;
      };

      function TouchMove(e) {
          if (initialX === null) {
            return;
          }

          if (initialY === null) {
            return;
          }

          var currentX = e.touches[0].clientX;
          var currentY = e.touches[0].clientY;

          var diffX = initialX - currentX;
          var diffY = initialY - currentY;

          if (Math.abs(diffX) > Math.abs(diffY)) {
            // sliding horizontally
            if (diffX > 0) {
              // swiped left
              if(gallery_index < jabari_arrey.length-1){
                gallery_index++
                jabari_gallery.css({
                    "background-image":`url(${jabari_arrey[gallery_index]})`
                }) 
                console.log(gallery_index)
                
            }

            } else {
              // swiped right
              if(gallery_index < jabari_arrey.length && gallery_index > 0){
                gallery_index--
                jabari_gallery.css({
                    "background-image":`url(${jabari_arrey[gallery_index]})`
                }) 
                console.log(gallery_index)
                
            }
            
              

            }  
          } 

          initialX = null;
          initialY = null;

          e.preventDefault();
        };

          

    //Click to show gallery
            function showJabariImages(){
              

              const id = $(this).attr('data-id')
              jabari_gallery.css({
                  "background-image":`url(${jabari_arrey[id]})`
              })
              gallery_index =id; 
              console.log(id)

              gallery_main_container.show()
              
            }

            //Close Button

            var close_button = $('.close_button');
            close_button.click(closeVideo)
            function closeVideo(){
             
              gallery_main_container.hide()
              video_container.css({
                opacity:0,
                visibility:'hidden',
                display:"none"
              })

              video[0].pause()
              video.currentTime = 0;
            }
            
        
          }
      },
      {
        
        namespace: 'tutu',
        beforeEnter() {
          var css = "<link rel='stylesheet' href='css/styles.css'>"
          $('head').append(css);

          const footer_text = $('footer .container .flexbox .flex:nth-child(1) p')
          const slice_text = footer_text.text()
          const small_screens = window.matchMedia('(max-width:380px)');

          if(small_screens.matches){
            slice_text_template =`
            ${slice_text.slice(0,150)}... <a href="about.html" style="color:white;">Read More</a>
            `
            footer_text.html(slice_text_template)
    
              console.log(slice_text.slice(0,150))
          }
        },
        afterEnter(){
          var toggle_bool = false;

          //Mobile navigation
          const menu = $('.menu');
          const mobile_nav_menu = $('.mobile_nav_menu')
          const mobile_nav_menu_container = $('.mobile_nav_menu .container')
          const mobile_menu_li = $('.mobile_menu ul li')
          menu.click(ShowMobileNav)

          function ShowMobileNav(){
            mobile_nav_menu.show()

            
          if(!toggle_bool){
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              alpha:1,
              x:0,
              delay:.1,
              ease:'power4.out',
             
            })
          }else{
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              x:'100px',
              alpha:0,
              ease:'power4.out'
             
            })

         
              setTimeout(function(){
                mobile_nav_menu.hide()
              },200)
             
            
           
          }
          toggle_bool =!toggle_bool;

            
          }

          mobile_menu_li.click(function(){
            toggle_bool = false;
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              delay:.5,
              x:'100px',
              alpha:0,
              ease:'power4.out'
             
            })

            setTimeout(function(){
              mobile_nav_menu.hide()
            },600)
          })
            //Tutu Gallery    
  
            var gallery_flexbox = $('.gallery_flexbox')
            const tutu_trailer = $('.tutu_trailer')
            const tutu_behind_the_scene = $('.tutu_behind_the_scene')
            const tutu_animatics = $('.tutu_animatics')
            const script_to_screen_button = $('.script_to_screen_button')
            const title_reveal = $('.title_reveal')
            var video_container = $('.video_container')
            var video = $('.video_container .video video')
            const tutu_view_button=$('.tutu_view_button')
            const character_gallery_main_container = $('.character_gallery_main_container');
            const character_gallery = $('.character_gallery')
            const arrow_left = $('.arrow_left');
            const arrow_right = $('.arrow_right')
            const tutu_arrow_left = $('.tutu_arrow_left');
            const tutu_arrow_right = $('.tutu_arrow_right')
            const tutu_gallery_main_container = $('.tutu_gallery_main_container')
            const tutu_gallery = $('.tutu_gallery')
            
            var gallery_index = 0;
            
            //Click Functions
            tutu_trailer.click(showTutuTrailer)
            tutu_behind_the_scene.click(showBehindTheScenes)
            tutu_animatics.click(showAnimatics)
            script_to_screen_button.click(showScriptToScreen)
            title_reveal.click(showTitleReveal) 
            tutu_view_button.click(showCharaterDesignGallery)

            var tutu_arrey = [
            poster1,
            poster2,
            poster3,
            poster4,
            poster5,
            poster6,
            poster7,
            poster8,
            poster9,

          ]
          
          var tutu_arrey_hiRes = [
              poster1,
            poster2,
            poster3,
            poster4,
            poster5,
            poster6,
            poster7,
            poster8,
            poster9,

          ]

        
          const character_designs_array =[
            tutu_char1,
            tutu_char2,
            tutu_char3,
            tutu_char4,
            tutu_char5,
            tutu_char6,
            tutu_char7,
            tutu_char8,
            
          ]

          tutu_arrey.forEach((array_data,index)=>{
            const image_template =`
                <div data-id=${index} class='gallery_images' style="background:url(${array_data});background-size:cover; background-position:center;">
                  
                </div>
            `
        
            gallery_flexbox.append(image_template);
        })

        const gallery_images = $('.gallery_images')
        gallery_images.click(showTutuImages)

        function showTutuTrailer(){
          video_container.css({
            opacity:1,
            visibility:'visible',
            display:'block'
        })
          video.attr('src',tutu_video)
          
          video.attr('poster',tutu_video_poster)
          
         
          video[0].play()
          
        }

        function showBehindTheScenes(){
          video_container.css({
            opacity:1,
            visibility:'visible',
            display:'block'
        })
          video.attr('src',behind_the_scenes)
         
          video.attr('poster',behind_the_scenes_poster)
          
         
          video[0].play()
          
        }

        function showAnimatics(){
          video_container.css({
            opacity:1,
            visibility:'visible',
            display:'block'
        })
          video.attr('src', tutu_animatics_video)
          
          video.attr('poster',tutu_animatics_video_poster)
         
          video[0].play()
          
        }

        function showScriptToScreen(){
          video_container.css({
            opacity:1,
            visibility:'visible',
            display:'block'
        })
          video.attr('src',script_to_screen)
          
          video.attr('poster',script_to_screen_poster)
         
          video[0].play()
          
        }

        function showTitleReveal(){
          video_container.css({
            opacity:1,
            visibility:'visible',
            display:'block'
        })
          video.attr('src',title_reveal_video)
          video.attr('poster', title_reveal_video_poster)
         
          video[0].play()
          
        }

        var close_button = $('.close_button');
        close_button.click(closeVideo)
        close_button.css({
          opacity:1
        })
        function closeVideo(){
                    
          video_container.css({
            opacity:0,
            visibility:'hidden',
            display:'none'
        })
        video[0].pause()
        character_gallery_main_container.hide()
        tutu_gallery_main_container.hide()
        gallery_index=0;

        }

       /* const new_Array =[];

        function preload(url,callback){
          var img = new Image();
          img.src = url;
          img.onload = callback;

          console.log(url)

          
          new_Array.push(url)
      }

 

      character_designs_array.forEach(new_images =>{
        preload(new_images,function(){
          console.log('image preloaded')
        })
      })
 
      
      console.log(new_Array)*/

      

        function showCharaterDesignGallery(){
          character_gallery_main_container.css({
                display:'block'
          })
          character_gallery.css({
            'background-image':`url(${character_designs_array[0]})`,
            
         
          })
        }

        const preloadImage = src => 
        new Promise((resolve, reject) => {
          const image = new Image()
          image.onload = resolve
          image.onerror = reject
          image.src = src

          console.log(src)

          
        })

        async function preloader(){
           // Preload an image
           console.log('i m loading the images')
            await preloadImage("https://drive.google.com/uc?export=view&id=1d4dqVcfKgXDI4hCYFwGICZWHverYsxJy")
            
            await preloadImage("https://drive.google.com/uc?export=view&id=1dbo-slhWHHSWKMd_DU9qSR8z6wshthZE")
            await preloadImage("https://drive.google.com/uc?export=view&id=1J7zMyIP5_lwWlgC-8hRUy640LIgQd8If")
            await preloadImage("https://drive.google.com/uc?export=view&id=1NnHM-pKMacaMQx9oGBaCkWBNASHFyyS4")
           
            await preloadImage("https://drive.google.com/uc?export=view&id=1flFp4pvQU2K3u50EYa3Fv_7PdOg9EArY")
            await preloadImage("https://drive.google.com/uc?export=view&id=1sWCcPDc9lF1adGgDXleNnu6oqzoy3JZl")
            await preloadImage("https://drive.google.com/uc?export=view&id=1hsgWHkFHudxnw2SYxVVAAtJJOar-dW8D")
            await preloadImage("https://drive.google.com/uc?export=view&id=1S5dvsCBTL2G-egQm2wGXFjhB8AGSWtaj")
            console.log('i have finished preloading the images')
            
        }
       
        preloader()
        

        //End of Character Designs Controls

        tutu_arrow_right.click(function(){
   
          if(gallery_index < tutu_arrey_hiRes.length-1){
            gallery_index++
            tutu_gallery.css({
                "background-image":`url(${tutu_arrey_hiRes[gallery_index]})`
            }) 
            console.log(gallery_index)
            
        }
      
        })

        arrow_right.click(function(){
   

          if(gallery_index < character_designs_array.length-1){
              gallery_index++
              character_gallery.css({
                  "background-image":`url(${character_designs_array[gallery_index]})`
              }) 
              console.log(gallery_index)
              
          }

         
      
        })
        
        arrow_left.click(function(){
          
        
            if(gallery_index < character_designs_array.length && gallery_index > 0){
                gallery_index--
                character_gallery.css({
                    "background-image":`url(${character_designs_array[gallery_index]})`
                }) 
                console.log(gallery_index)
                
            }

            
        
        })
        
        tutu_arrow_left.click(function(){
          
            if(gallery_index < tutu_arrey_hiRes.length && gallery_index > 0){
              gallery_index--
             tutu_gallery.css({
                  "background-image":`url(${tutu_arrey_hiRes[gallery_index]})`
              }) 
              console.log(gallery_index)
              
          }
        
        })

        //Touch Functions for Character Design Gallery
        character_gallery_main_container.on('touchstart',TouchStart1);
        character_gallery_main_container.on('touchmove',TouchMove1); 

        var initialX = null;
        var initialY = null;

        function TouchStart1(e) {
            initialX = e.touches[0].clientX;
            initialY = e.touches[0].clientY;
           
        };

        function TouchMove1(e) {
     
            if (initialX === null) {
              return;
            }

            if (initialY === null) {
              return;
            }

            var currentX = e.touches[0].clientX;
            var currentY = e.touches[0].clientY;

            var diffX = initialX - currentX;
            var diffY = initialY - currentY;

            if (Math.abs(diffX) > Math.abs(diffY)) {
              // sliding horizontally
              if (diffX > 0) {
                // swiped left
                if(gallery_index < character_designs_array.length-1){
                  gallery_index++
                  character_gallery.css({
                      "background-image":`url(${character_designs_array[gallery_index]})`
                  }) 
                  console.log(gallery_index)
                
                  
              }
              

              } else {
                // swiped right
                if(gallery_index < character_designs_array.length && gallery_index > 0){
                  gallery_index--
                  character_gallery.css({
                      "background-image":`url(${character_designs_array[gallery_index]})`
                  }) 
                  console.log(gallery_index)
                  
              }

              }  
            } 

            initialX = null;
            initialY = null;

            e.preventDefault();
          };

          //Touch Functions for Tutu Main Gallery
        tutu_gallery_main_container.on('touchstart',TouchStart);
        tutu_gallery_main_container.on('touchmove',TouchMove); 

        var initialX = null;
        var initialY = null;

        function TouchStart(e) {
            initialX = e.touches[0].clientX;
            initialY = e.touches[0].clientY;
        };

        function TouchMove(e) {
          
            if (initialX === null) {
              return;
            }

            if (initialY === null) {
              return;
            }

            var currentX = e.touches[0].clientX;
            var currentY = e.touches[0].clientY;

            var diffX = initialX - currentX;
            var diffY = initialY - currentY;

            if (Math.abs(diffX) > Math.abs(diffY)) {
              // sliding horizontally
              if (diffX > 0) {
                // swiped left
                
                if(gallery_index < tutu_arrey_hiRes.length-1){
                  gallery_index++
                  tutu_gallery.css({
                      "background-image":`url(${tutu_arrey_hiRes[gallery_index]})`
                  }) 
                }
                
              

              } else {
                // swiped right
                
                if(gallery_index < tutu_arrey_hiRes.length && gallery_index > 0){
                  gallery_index--
                 tutu_gallery.css({
                      "background-image":`url(${tutu_arrey_hiRes[gallery_index]})`
                  }) 
                  console.log(gallery_index)
                  
              }
              

              }  
            } 

            initialX = null;
            initialY = null;

            e.preventDefault();
          };

        function showTutuImages(){
            tutu_gallery_main_container.show()
            const id = $(this).attr('data-id')
            tutu_gallery.css({
                "background-image":`url(${tutu_arrey_hiRes[id]})`
            })
            gallery_index =id; 
          console.log(id)
        }

        

        
  
        //End of Tutu AfterEnter() Functions  
        }
      },
      {
        
        namespace: 'mmofra',
        beforeEnter() {
          var css = "<link rel='stylesheet' href='css/styles.css'>"
          $('head').append(css);

          const footer_text = $('footer .container .flexbox .flex:nth-child(1) p')
          const slice_text = footer_text.text()
          const small_screens = window.matchMedia('(max-width:380px)');

          if(small_screens.matches){
            slice_text_template =`
            ${slice_text.slice(0,150)}... <a href="about.html" style="color:white;">Read More</a>
            `
            footer_text.html(slice_text_template)
    
              console.log(slice_text.slice(0,150))
          }
        },
        afterEnter(){
          var toggle_bool = false;

          //Mobile navigation
          const menu = $('.menu');
          const mobile_nav_menu = $('.mobile_nav_menu')
          const mobile_nav_menu_container = $('.mobile_nav_menu .container')
          const mobile_menu_li = $('.mobile_menu ul li')
          menu.click(ShowMobileNav)

          function ShowMobileNav(){
            mobile_nav_menu.show()

            
          if(!toggle_bool){
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              alpha:1,
              x:0,
              delay:.1,
              ease:'power4.out',
             
            })
          }else{
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              x:'100px',
              alpha:0,
              ease:'power4.out'
             
            })

         
              setTimeout(function(){
                mobile_nav_menu.hide()
              },200)
             
            
           
          }
          toggle_bool =!toggle_bool;

            
          }

          mobile_menu_li.click(function(){
            toggle_bool = false;
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              delay:.5,
              x:'100px',
              alpha:0,
              ease:'power4.out'
             
            })

            setTimeout(function(){
              mobile_nav_menu.hide()
            },600)
          })
          //Variables
          var video_container = $('.video_container')
          var video = $('.video_container .video video')
          const mmofra_trailer_play_button = $('.mmofra_trailer_play_button')
          const close_button = $('.close_button')


          const mmofra_array =[
           mmofra1,
           mmofra2,
           mmofra3,
           mmofra4,
           mmofra5,
           mmofra6,
           mmofra7,
           mmofra8,
           mmofra9,
           mmofra10,
           mmofra11,
           mmofra12,
           mmofra13,
           mmofra14,

        
          ]
          
          mmofra_array.forEach((array_data,index)=>{
              var gallery_flexbox = $('.gallery_flexbox')
              const image_template =`
                  <div data-id='${index}' class='gallery_images gallery_images_mmofra' style="background:url(${array_data});background-size:cover; background-position:top;">
                  <div class="overlay">
                  <img src="${play_butt}" alt="animax play button">
                 
                  </div>
                    
                  </div>
              `
          
              gallery_flexbox.append(image_template);
          })

          //click Functions
          mmofra_trailer_play_button.click(showMmofraTrailer)  
          close_button.click(closeVideo)

          function closeVideo(){
            video_container.css({
              opacity:0,
              visibility:'hidden',
              display:"none"
            })

            video[0].pause()
          }

          function showMmofraTrailer(){
            video_container.css({
              opacity:1,
              visibility:'visible',
              display:"block"
            })
              video.attr('src','https://www.dropbox.com/scl/fi/7u2o2w6l9mwqcpaf1jf25/tv_movies.mp4?rlkey=f1kdo0um9hq5k6eo0annjp0bb&raw=1')
             
              video.attr('poster',mmofra_cover)
              
              
            
              video[0].play()
          }

          const gallery_images_mmofra = $('.gallery_images_mmofra')

          gallery_images_mmofra.click(function(){
          
            var id =$(this).attr('data-id');

            if(id == 0){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo1)
                video.attr('poster',promo_poster1)
                
                video[0].play()
            }

            if(id == 1){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
                
              })
                video.attr('src',promo2)
                video.attr('poster',promo_poster2)
               
                video[0].play()
            }

            if(id == 2){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo3)
                video.attr('poster',promo_poster3)
                
                video[0].play()
            }

            if(id == 3){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo4)
                video.attr('poster',promo_poster4)
                
                video[0].play()
            }

            if(id == 4){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo5)
                video.attr('poster',promo_poster5)
                
                video[0].play()
            }

            if(id == 5){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo6)
                video.attr('poster',promo_poster6)
                video[0].play()
            }

            if(id == 6){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo7)
                video.attr('poster',promo_poster7)
                video[0].play()
            }

            if(id == 7){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo8)
                video.attr('poster',promo_poster8)
                video[0].play()
            }

            if(id == 8){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo9)
                video.attr('poster',promo_poster9)
                video[0].play()
            }

            if(id == 9){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo10)
                video.attr('poster',promo_poster10)
                video[0].play()
            }

            if(id == 10){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo11)
               
                video.attr('poster',promo_poster11)
                video[0].play()
            }

            if(id == 11){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo12)
                video.attr('poster',promo_poster12)
                
                video[0].play()
            }

            if(id == 12){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo13)
                video.attr('poster',promo_poster13)
                
                video[0].play()
            }

            if(id == 13){
              video_container.css({
                opacity:1,
                visibility:'visible',
                display:"block"
              })
                video.attr('src',promo14)
                video.attr('poster',promo_poster14)
                
                video[0].play()
            }
        
          })

          //End Of Mmofra afterEnter()
        }
      },
      {
        
        namespace: 'wips',
        beforeEnter() {
          var css = "<link rel='stylesheet' href='css/styles.css'>"
          $('head').append(css);

          const footer_text = $('footer .container .flexbox .flex:nth-child(1) p')
          const slice_text = footer_text.text()
          const small_screens = window.matchMedia('(max-width:380px)');

          if(small_screens.matches){
            slice_text_template =`
            ${slice_text.slice(0,150)}... <a href="about.html" style="color:white;">Read More</a>
            `
            footer_text.html(slice_text_template)
    
              console.log(slice_text.slice(0,150))
          }
        },
        afterEnter(){

          var toggle_bool = false;

          //Mobile navigation
          const menu = $('.menu');
          const mobile_nav_menu = $('.mobile_nav_menu')
          const mobile_nav_menu_container = $('.mobile_nav_menu .container')
          const mobile_menu_li = $('.mobile_menu ul li')
          menu.click(ShowMobileNav)

          function ShowMobileNav(){
            mobile_nav_menu.show()

            
          if(!toggle_bool){
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              alpha:1,
              x:0,
              delay:.1,
              ease:'power4.out',
             
            })
          }else{
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              x:'100px',
              alpha:0,
              ease:'power4.out'
             
            })

         
              setTimeout(function(){
                mobile_nav_menu.hide()
              },200)
             
            
           
          }
          toggle_bool =!toggle_bool;

            
          }

          mobile_menu_li.click(function(){
            toggle_bool = false;
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              delay:.5,
              x:'100px',
              alpha:0,
              ease:'power4.out'
             
            })

            setTimeout(function(){
              mobile_nav_menu.hide()
            },600)
          })

          var video_container = $('.video_container')
          var video = $('.video_container .video video')
          const bakari_play_button = $('.bakari_play_button')
          var close_button = $('.close_button');
          const view_moongirls = $('.view_moongirls');
          var gallery_main_container = $('.gallery_main_container')
          const gallery= $('.gallery')
          var close_gallery = $('.close_gallery');
          const gallery_content = $('.gallery_content')
          let new_gallery = $('<div></div>')

         //click Functions
         view_moongirls.click(viewMoonGirls)

         /*const viewMoonGirls = ()=>{
           gallery_main_container.show()
           alert()
         }*/

         function viewMoonGirls(){
          gallery_main_container.show()
         }

          const moongirls_gallery = [
            moon1,
            moon2,
            moon3,
            moon4,
            moon5
        ]
          

          bakari_play_button.click(showBakariVideo)
          close_button.click(closeVideo)
          close_gallery.click(closeGallery)

          //EventListeners
          video_container.on('mousemove',showCloseButton)
   
          function closeVideo(){
            video_container.css({
                opacity:0,
                visibility:'hidden',
                display:'none'
            })
    
            video[0].pause()
           
          }

          function closeGallery(){
            gallery_main_container.hide()
          
          }

          function closeButtonAction(){
            close_button.css({
                opacity:1,
                top:'5%'
            })
    
        }
    
        function showCloseButton (){
           closeButtonAction();
        }

        //Bakari Video

          function showBakariVideo(){
            video_container.css({
              opacity:1,
              visibility:'visible',
              display:'block'
          })
           video.attr('src', bakari)
          
            
          video.attr('poster', bakari_img)
          
           
           
            video[0].play()
            
          }

                    
          const arrow_left = $('.arrow_left');
          const arrow_right = $('.arrow_right')

          var gallery_index = 0;
          var move=0
          var value = 20;


          
          moongirls_gallery.forEach((images,index)=>{
            const gallery_template = `
            <div id="moon${index}">
            <img src="${images}" alt="animaxfyb gallery"> 
            </div>
            `
            new_gallery.append(gallery_template)
            gallery.html(new_gallery)
            
            

          })



          arrow_right.click(function(){

              
              if(gallery_index < moongirls_gallery.length-1){
                  gallery_index++
                  console.log(gallery_index)
                  move+=value;
                  //arrow_right_link.attr('href',`#moon${gallery_index}`)
                  gallery.css({
                      "transform":`translateX(-${move}%)`,
                      
                  })
                
                  
              }

            
            
          })

          arrow_left.click(function(){

              
              if(gallery_index < moongirls_gallery.length && gallery_index > 0){
                  gallery_index--;
                  console.log(gallery_index)
                  move-=value;
                  //arrow_left_link.attr('href',`#moon${gallery_index}`)
                  gallery.css({
                      "transform":`translateX(-${move}%)`,
                      
                  })
                
              }  
              
              
          })

          
        
        
          


          //End of afterEnter()
        }
      },
      {
        namespace: 'csi',
        beforeEnter() {
          var css = "<link rel='stylesheet' href='css/styles.css'>"
          $('head').append(css);

          const footer_text = $('footer .container .flexbox .flex:nth-child(1) p')
          const slice_text = footer_text.text()
          const small_screens = window.matchMedia('(max-width:380px)');

          if(small_screens.matches){
            slice_text_template =`
            ${slice_text.slice(0,150)}... <a href="about.html" style="color:white;">Read More</a>
            `
            footer_text.html(slice_text_template)
    
              console.log(slice_text.slice(0,150))
          }
        },
        afterEnter(){
                  
        //Gsap Animations
        var transition1 = $('.transition1');
        var transition2 = $('.transition2');
        //var navigations = $('a')
        gsap.set(transition1,
            {x:'-100%',
            alpha:1
        })
        gsap.to(transition1,{
            x:0,
            duration: 1, 
            ease: "power4.inOut",
            delay:.2
            
        })
        gsap.to(transition1,{
            x:"100%",
            delay:5,
            duration: 1, 
            ease: "power4.inOut"
        })

        //transition2

        gsap.set(transition2,
            {x:'-100%',
            alpha:1
        })
        gsap.to(transition2,{
            x:0,
            duration: 1, 
            ease: "power4.inOut"
            
        })
        gsap.to(transition2,{
            x:"100%",
            delay:5.1,
            duration: 1, 
            ease: "power4.inOut"
        })

        //End of Gsap Animation

                     
        var toggle_bool = false;

        //Mobile navigation
        const menu = $('.menu');
        const mobile_nav_menu = $('.mobile_nav_menu')
        const mobile_nav_menu_container = $('.mobile_nav_menu .container')
        const mobile_menu_li = $('.mobile_menu ul li')
        menu.click(ShowMobileNav)

        function ShowMobileNav(){
          mobile_nav_menu.show()

          
        if(!toggle_bool){
          gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
            alpha:1,
            x:0,
            delay:.1,
            ease:'power4.out',
           
          })
        }else{
          gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
            x:'100px',
            alpha:0,
            ease:'power4.out'
           
          })

       
            setTimeout(function(){
              mobile_nav_menu.hide()
            },200)
           
          
         
        }
        toggle_bool =!toggle_bool;

          
        }

        mobile_menu_li.click(function(){
          toggle_bool = false;
          gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
            delay:.5,
            x:'100px',
            alpha:0,
            ease:'power4.out'
           
          })

          setTimeout(function(){
            mobile_nav_menu.hide()
          },600)
        })
        

        }

      },
      {
        namespace:'highlights',
        beforeEnter(){

        },
        afterEnter(){
          
           //variables
           var video_container = $('.video_container')
           var video = $('.video_container .video video')
           var high_play_button_1 = $('.high_play_button_1')
           var high_play_button_2 = $('.high_play_button_2')
           var high_play_button_3 = $('.high_play_button_3')
           var high_play_button_4 = $('.high_play_button_4')
           var high_play_button_5 = $('.high_play_button_5')
           var high_play_button_6 = $('.high_play_button_6')

           var mobile_unilever_play_button = $('.mobile_unilever_play_button')
           const mobile_nike_play_button = $('.mobile_nike_play_button')
           var mobile_gates_play_button = $(".mobile_gates_play_button")
           var mobile_ident_play_button = $('.mobile_ident_play_button')
           var mobile_french_embassy_play_button = $('.mobile_french_embassy_play_button')
           var mobile_goal_setters_play_button = $('.mobile_goal_setters_play_button')
           var mobile_unicef_play_button = $('.mobile_unicef_play_button')
           

           //click functions
           high_play_button_1.add(mobile_unilever_play_button).click(hightlightVideo1)
           high_play_button_2.add(mobile_gates_play_button).click(hightlightVideo2)
           high_play_button_3.add(mobile_ident_play_button).click(hightlightVideo3)
           high_play_button_4.add(mobile_french_embassy_play_button).click(hightlightVideo4)
           high_play_button_5.add(mobile_goal_setters_play_button).click(hightlightVideo5)
           high_play_button_6.add(mobile_unicef_play_button).click(hightlightVideo6)

           

           //nav indicator
           var highlight = $('.highlight')

          //  highlight.css({
          //   'border-bottom':"6px solid #F79421"
          //  })

          //Nike mimi x plange click to play video codes here....
          mobile_nike_play_button.click(()=>{
            video_container.css({
              opacity:1,
              visibility:'visible',
              display:'block'
          })
            video.attr('src',nike_vid)
            video.attr('poster',nike_poster2)
            video[0].play()
           })

          function hightlightVideo1(){
            video_container.css({
              opacity:1,
              visibility:'visible',
              display:'block'
          })
            video.attr('src',uniliver)
            video.attr('poster',uniliver_poster)
            video[0].play()
            
          }
         
          function hightlightVideo2(){
            video_container.css({
              opacity:1,
              visibility:'visible',
              display:'block'
          })
            video.attr('src',gates)
            video.attr('poster',gates_poster)
            video[0].play()
            
          }

          function hightlightVideo3(){
            video_container.css({
              opacity:1,
              visibility:'visible',
              display:'block'
          })
            video.attr('src',ident)
            video.attr('poster',ident_poster)
            video[0].play()
          }

          function hightlightVideo4(){
            video_container.css({
              opacity:1,
              visibility:'visible',
              display:'block'
          })
            video.attr('src',french_embassy)
            video.attr('poster',french_embassy_poster)
            video[0].play()
          }

          function hightlightVideo5(){
            video_container.css({
              opacity:1,
              visibility:'visible',
              display:'block'
          })
          
            video.attr('src',goal_setters)
            video.attr('poster',goal_setters_poster)
            video[0].play()
          }

          function hightlightVideo6(){
           video_container.css({
             opacity:1,
             visibility:'visible',
             display:'block'
         })
        
           video.attr('src',unicef)
           video.attr('poster',unicef_poster)
           video[0].play()
         }

         var close_button = $('.close_button')
         close_button.click(closeVideo)
         function closeVideo(){
            video_container.css({
              opacity:0,
              visibility:'hidden',
              display:'none'
            })

            video[0].pause()
           }      
        }
      },
      {
        namespace:'tvmovies',
        beforeEnter(){
          const footer_text = $('footer .container .flexbox .flex:nth-child(1) p')
          const slice_text = footer_text.text()
          const small_screens = window.matchMedia('(max-width:380px)');

          if(small_screens.matches){
            slice_text_template =`
            ${slice_text.slice(0,150)}... <a href="about.html" style="color:white;">Read More</a>
            `
            footer_text.html(slice_text_template)
    
              console.log(slice_text.slice(0,150))
          }
        },
        afterEnter(){
          var toggle_bool = false;
          var tv_movie = $('.tv_movie');

          // tv_movie.css({
          //   'border-bottom':"6px solid #F79421"
          // })

          //Mobile navigation
          const menu = $('.menu');
          const mobile_nav_menu = $('.mobile_nav_menu')
          const mobile_nav_menu_container = $('.mobile_nav_menu .container')
          const mobile_menu_li = $('.mobile_menu ul li')
          menu.click(ShowMobileNav)

          function ShowMobileNav(){
            mobile_nav_menu.show()

            
          if(!toggle_bool){
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              alpha:1,
              x:0,
              delay:.1,
              ease:'power4.out',
             
            })
          }else{
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              x:'100px',
              alpha:0,
              ease:'power4.out'
             
            })

         
              setTimeout(function(){
                mobile_nav_menu.hide()
              },200)
             
            
           
          }
          toggle_bool =!toggle_bool;

            
          }

          mobile_menu_li.click(function(){
            toggle_bool = false;
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              delay:.5,
              x:'100px',
              alpha:0,
              ease:'power4.out'
             
            })

            setTimeout(function(){
              mobile_nav_menu.hide()
            },600)
          })
           //variables
           var video_container = $('.video_container')
           var video = $('.video_container .video video')
           var high_play_button_1 = $('.high_play_button_1')
           var high_play_button_2 = $('.high_play_button_2')
           var high_play_button_3 = $('.high_play_button_3')
           var high_play_button_4 = $('.high_play_button_4')
           var high_play_button_5 = $('.high_play_button_5')
           var high_play_button_6 = $('.high_play_button_6')

           var tv_movies_play_button1 = $('.tv_movies_play_button_1')
           var tv_movies_play_button2 = $('.tv_movies_play_button_2')
           var tv_movies_play_button3 = $('.tv_movies_play_button_3')
           var tv_movies_play_button4 = $('.tv_movies_play_button_4')
           var tv_movies_play_button5 = $('.tv_movies_play_button_5')
           var tv_movies_play_button6 = $('.tv_movies_play_button_6')

           var mobile_room5_play_button = $(".mobile_room5_play_button")
           var mobile_kilo_play_button = $(".mobile_kilo_play_button")
           var mobile_xena_play_button = $(".mobile_xena_play_button")
           var mobile_way2go_play_button = $(".mobile_way2go_play_button")
           var mobile_joe_boy_play_button = $(".mobile_joe_boy_play_button")
           var mobile_argokoli_play_button = $(".mobile_argokoli_play_button")
           
           var close_button = $('.close_button')

        

           //click functions
           high_play_button_1.click(hightlightVideo1)
           high_play_button_2.click(hightlightVideo2)
           high_play_button_3.click(hightlightVideo3)
           high_play_button_4.click(hightlightVideo4)
           high_play_button_5.click(hightlightVideo5)
           high_play_button_6.click(hightlightVideo6)

           
           tv_movies_play_button1.add(mobile_room5_play_button).click(tvMoviesVideo1)
           tv_movies_play_button2.add(mobile_kilo_play_button).click(tvMoviesVideo2)
           tv_movies_play_button3.add(mobile_xena_play_button).click(tvMoviesVideo3)
           tv_movies_play_button4.add(mobile_way2go_play_button).click(tvMoviesVideo4)
           tv_movies_play_button5.add(mobile_joe_boy_play_button).click(tvMoviesVideo5)
           tv_movies_play_button6.add(mobile_argokoli_play_button).click(tvMoviesVideo6)

           close_button.click(closeVideo)


           function hightlightVideo1(){
             video_container.css({
               opacity:1,
               visibility:'visible',
               display:'block'
           })
             video.attr('src',uniliver)
             video.attr('poster',uniliver_poster)
             video[0].play()
             
           }
          
           function hightlightVideo2(){
             video_container.css({
               opacity:1,
               visibility:'visible',
               display:'block'
           })
             video.attr('src',gates)
             video.attr('poster',gates_poster)
             video[0].play()
             
           }

           function hightlightVideo3(){
             video_container.css({
               opacity:1,
               visibility:'visible',
               display:'block'
           })
             video.attr('src',ident)
             video.attr('poster',ident_poster)
             video[0].play()
           }

           function hightlightVideo4(){
             video_container.css({
               opacity:1,
               visibility:'visible',
               display:'block'
           })
             video.attr('src',french_embassy)
             video.attr('poster',french_embassy_poster)
             video[0].play()
           }

           function hightlightVideo5(){
             video_container.css({
               opacity:1,
               visibility:'visible',
               display:'block'
           })
           
             video.attr('src',goal_setters)
             video.attr('poster',goal_setters_poster)
             video[0].play()
           }

           function hightlightVideo6(){
            video_container.css({
              opacity:1,
              visibility:'visible',
              display:'block'
          })
         
            video.attr('src',unicef)
            video.attr('poster',unicef_poster)
            video[0].play()
          }

           function tvMoviesVideo1(){
             video_container.css({
               opacity:1,
               visibility:'visible',
               display:'block'
           })
             video.attr('src',room5)
             video.attr('poster',room5_poster)
             video[0].play()

             if(mobile_view.matches){
              video[0].requestFullscreen()
            }
            
           }

           function tvMoviesVideo2(){
             video_container.css({
               opacity:1,
               visibility:'visible',
               display:'block'
           })
             video.attr('src',kilo_kwataa)
             video.attr('poster',kilo_kwataa_poster)
             video[0].play()
             
             if(mobile_view.matches){
              video[0].requestFullscreen()
            }
           }

           function tvMoviesVideo3(){
             video_container.css({
               opacity:1,
               visibility:'visible',
               display:'block'
           })
             video.attr('src',xena)
             video.attr('poster',xena_poster)
             video[0].play()
             
             if(mobile_view.matches){
              video[0].requestFullscreen()
            }
           }

           function tvMoviesVideo4(){
             video_container.css({
               opacity:1,
               visibility:'visible',
               display:'block'
           })
             video.attr('src',way2go)
             video.attr('poster',way2go_poster)
             video[0].play()
            
             if(mobile_view.matches){
              video[0].requestFullscreen()
            }
           }

           function tvMoviesVideo5(){
             video_container.css({
               opacity:1,
               visibility:'visible',
               display:'block'
           })
             video.attr('src',joe_boy)
             video.attr('poster',joe_boy_poster)
             video[0].play()
             
             if(mobile_view.matches){
              video[0].requestFullscreen()
            }
           }

           function tvMoviesVideo6(){
             video_container.css({
               opacity:1,
               visibility:'visible',
               display:'block'
           })
             video.attr('src',argokoli)
             video.attr('poster',argokoli_poster)
            
             video[0].play()
             video.currentTime = 0;
             
             if(mobile_view.matches){
              video[0].requestFullscreen()
            }
           }

           function closeVideo(){
            video_container.css({
              opacity:0,
              visibility:'hidden',
              display:'none'
            })

            video[0].pause()
           }

         
        }
      },{
        namespace:'about',
        beforeEnter(){
          
        },
        afterEnter(){
          //alert()
          const awards_holder = $('.awards_holder')
          const client_carousel = $('.clients_carousel')
          const mobile_view = window.matchMedia('(max-width:900px)')
          const total_movement = 2;
          var movement_index = 1;
          var addValue = 34;
          var addValue_2 = 50;
          var moveValue = 0;
          var aboutTime = 4000;
          var clientTime = 2000;

          function aboutCarousel(){
              if(movement_index < total_movement){
                moveValue += addValue_2;

                awards_holder.css({
                  transform:`translateX(-${moveValue}%)`
                })
                movement_index++;
              }else{
                moveValue =0;
                movement_index=1;
                awards_holder.css({
                  transform:`translateX(-${moveValue}%)`
                })
                
              }
          }

          function clientCarousel(){
            if(movement_index < total_movement){
              moveValue += addValue;

              client_carousel.css({
                transform:`translateX(-${moveValue}%)`
              })
              movement_index++;
            }else{
              moveValue =0;
              movement_index=1;
              client_carousel.css({
                transform:`translateX(-${moveValue}%)`
              })
              
            }
          }

          

          setInterval(aboutCarousel,aboutTime)
          setInterval(clientCarousel,clientTime)

          if(mobile_view.matches){
            const total_movement = 6;
            var mobile_movement_index = 1;
            var addValue3 = 11;
            var mobile_moveValue = 0;
            function mobile_clientCarousel(){
              if(mobile_movement_index < total_movement){
                mobile_moveValue += addValue3;
  
                client_carousel.css({
                  transform:`translateX(-${mobile_moveValue}%)`
                })
                mobile_movement_index++;
                console.log(mobile_movement_index)
              }else{
                mobile_moveValue =0;
                mobile_movement_index=1;
                client_carousel.css({
                  transform:`translateX(-${mobile_moveValue}%)`
                })
                
              }
            }

            setInterval(mobile_clientCarousel,clientTime)
            
          }

                       
          var toggle_bool = false;

          //Mobile navigation
          const menu = $('.menu');
          const mobile_nav_menu = $('.mobile_nav_menu')
          const mobile_nav_menu_container = $('.mobile_nav_menu .container')
          const mobile_menu_li = $('.mobile_menu ul li')
          menu.click(ShowMobileNav)

          function ShowMobileNav(){
            mobile_nav_menu.show()

            
          if(!toggle_bool){
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              alpha:1,
              x:0,
              delay:.1,
              ease:'power4.out',
             
            })
          }else{
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              x:'100px',
              alpha:0,
              ease:'power4.out'
             
            })

         
              setTimeout(function(){
                mobile_nav_menu.hide()
              },200)
             
            
           
          }
          toggle_bool =!toggle_bool;

            
          }

          mobile_menu_li.click(function(){
            toggle_bool = false;
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              delay:.5,
              x:'100px',
              alpha:0,
              ease:'power4.out'
             
            })

            setTimeout(function(){
              mobile_nav_menu.hide()
            },600)
          })

        }
      },{
        namespace:'contact',
        beforeEnter(){
          emailjs.init("3Rt0oYNnJhjkjo_GD");
          const send_mail = $('.send_mail');
          const message_sent_alert = $('.message_sent_alert')
          //send_mail.hide()
          send_mail.click(e=>{
            //e.preventDefault()
            
            var params={
              name : document.getElementById('name').value,
              last_name : document.getElementById('last_name').value,
              subject : document.getElementById('subject').value,
              service : document.getElementById('service').value,
              message : document.getElementById('message').value,
              email : document.getElementById('email').value,
            }

            var validation = params.name =="" || params.last_name=="" || params.subject=="" || params.message=="" && params.email==""

            if(validation){
                alert("Empty Field(s). Please Review")
            }else{
             
             
              emailjs.send('0576472475blessed','template_9ecklns',params).then(res=>{
                console.log('Success!' + res.status)
  
               
  
                if(res.status == '200'){
                  message_sent_alert.css(
                    {
                      "top":"5%",
                      "opacity":1,
                      'visibility':"visible"
                    }
                  )
  
                   setTimeout(function(){
  
                    message_sent_alert.css(
                      {
                        "top":"0%",
                        "opacity":0,
                        'visibility':"hidden"
                      }
                    )

                    $('form')[0].reset()
                   },2000) 
  
                }
              })

             
            }
            

          })

          $('form').submit((e)=>{
            e.preventDefault()
          })
        },
        afterEnter(){
          console.log('hello')
          
          
          
          var toggle_bool = false;

          //Mobile navigation
          const menu = $('.menu');
          const mobile_nav_menu = $('.mobile_nav_menu')
          const mobile_nav_menu_container = $('.mobile_nav_menu .container')
          const mobile_menu_li = $('.mobile_menu ul li')
          menu.click(ShowMobileNav)

          function ShowMobileNav(){
            mobile_nav_menu.show()

            
          if(!toggle_bool){
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              alpha:1,
              x:0,
              delay:.1,
              ease:'power4.out',
             
            })
          }else{
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              x:'100px',
              alpha:0,
              ease:'power4.out'
             
            })

         
              setTimeout(function(){
                mobile_nav_menu.hide()
              },200)
             
            
           
          }
          toggle_bool =!toggle_bool;

            
          }

          mobile_menu_li.click(function(){
            toggle_bool = false;
            gsap.to([mobile_nav_menu_container,mobile_nav_menu],{
              delay:.5,
              x:'100px',
              alpha:0,
              ease:'power4.out'
             
            })

            setTimeout(function(){
              mobile_nav_menu.hide()
            },600)
          })
          
       
          
        }
      }
    ]
  });



  

